import React from 'react'
import StandardPage from './StandardPage'

const HomePage = () => {

    return (
        <StandardPage />
    )
}

export default HomePage